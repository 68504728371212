export default function IngredientImage(props) {
  const { width, height, src, name } = props;

  return (
    <span
      className="ingredient-image-container"
      style={{ width: width, height: height }}
      >
      <img
        className='ingredient-image'
        src={src}
        alt={name}
        style={{ width: width, height: height }}
      />
    </span>
  )
}