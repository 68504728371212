import { useEffect, useState } from "react";

import CounterBlock from "../CounterBlock/CounterBlock";
import useScreenSize from "../../utils/useScreenSize";
import "./ProgressBarStep.css";
import Responses from "../../data/Responses.json";
import StyledPointerProgressBar from "./StyledPointerProgressBar";

export default function ProgressBarStep(props) {
  //states
  const [width, breakpoint] = useScreenSize();
  const [groupQuestionsByCategory, setGroupQuestionsByCategory] = useState([]); // array of names category
  const [namesCategories, setNamesCategories] = useState([]); // array of names category
  const [numberOfQuestionsInBlockArray, setNumberOfQuestionsInBlockArray] =
    useState([]); // array of number

  useEffect(() => {
    const uniqueCategories = {};

    const namesUniqueCategories = new Set(); // Step 1: Use the Set data structure to store unique category values
    Responses.forEach((item) => {
      namesUniqueCategories.add(item.category);
    });

    // Loop through each question and categorize them
    Responses.forEach((question) => {
      const category = question.category;

      if (!uniqueCategories[category]) {
        uniqueCategories[category] = [];
      }

      uniqueCategories[category] = [...uniqueCategories[category], question]; //uniqueCategories[category].push(question);
    });

    const getTheNumbersOfQuestionsForEachBlock = [
      ...Object.values(props.blocksLength),
    ];
    const uniqueCategoriesArray = [...Object.values(uniqueCategories)]; //Array.from(uniqueCategories); // Step 3: Convert the Set back to an array to get the unique category values
    const namesCategoriesArray = [...namesUniqueCategories];
    setGroupQuestionsByCategory([...uniqueCategoriesArray.slice(1)]); // Remove the first element from the array (index 0) and start from index 1
    setNamesCategories([...namesCategoriesArray.slice(1)]); // Remove the first element from the array (index 0) and start from index 1
    setNumberOfQuestionsInBlockArray([...getTheNumbersOfQuestionsForEachBlock]);

    //console.log("pograss bar step herre --> ", breakpoint, width);
  }, [Responses]);

  /**
   *
   * @returns
   */
  const categoryToFindIndexAndTheItem = () => {
    const categoryToFind = Responses[props.questionsCounter].category;
    let categoryIndex = -1;

    for (let i = 0; i < namesCategories.length; i++) {
      if (namesCategories[i] === categoryToFind) {
        categoryIndex = i;
        //matchingResponse = Responses.find(response => response.category === categoryToFind);
        return [categoryIndex, namesCategories[categoryIndex]]; // Stop the loop once we find the match
      }
    }
    return [];
  };

  /**
   *
   * @returns
   */
  const dynamicMarginLeft = () => {
    //console.log("here width ---->", width);

    let pointer = 0.5;

    if (props.questionsCounter >= 15 && props.questionsCounter <= 18) {
      //block 2
      pointer = `${20 + width * 0.1}px`;
    } else if (props.questionsCounter >= 19) {
      //block 3
      pointer = `${5 + width * 0.2}px`;
    }

    const p = `${10 + (pointer - 1) * 20}px`;
    // console.log("here #######>", p);
    return pointer;
  };

  //console.log("ProgressBarStep -->", props);
  //render
  return (
    <div className="progress-bar-step">
      <div className="step-counter-progress-container">
        <div className="step-counter-progress-bar-container">
          {
            //TODO TO CHANGE BY CATEGORY
            groupQuestionsByCategory.map((item, index) => (
              <CounterBlock
                numberOfQuestionsInBlock={numberOfQuestionsInBlockArray[index]}
                key={index} // Use category as the key
                blockId={index + 1} // Use category as the blockId
                /*innerBlockOrder={item}*/
                moveStep={props.moveStep}
                stepCount={props.stepCount}
                isOnStep={props.isOnStep}
                questionsCounter={props.questionsCounter}
                groupQuestionsByCategory={item}
                blocksLength={props.blocksLength}
                /*innerStepCount={props.innerStepCount}*/
              />
            ))
          }
        </div>
        <StyledPointerProgressBar marginValue={dynamicMarginLeft()}>
          {categoryToFindIndexAndTheItem()[1]}
        </StyledPointerProgressBar>
      </div>
    </div>
  );
}
