import { COMPANY } from "../../utils/constants";
import useScreenSize from "../../utils/useScreenSize";

const ImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/smoothies_ing/{id}.png`

export default function SmoothieTime(props) {
  const [screenWidth] = useScreenSize();

  const nutritionalBenefitsList = props.smoothieIngredients.map((ingredient) => {
    return (
      <li key={ingredient.ID}>{ingredient.Benefits}</li>
    )
  })

  const ingredientImgComponents = props.smoothieIngredients.map((ingredient) => {
    return (
      <div key={ingredient.ID} className="ingredient-img-container">
        <img src={ImgUrl.replace('{id}', ingredient.ID)} alt="smoothieIngredient" />
        <span>{ingredient.Ingredient}</span>
      </div>
    )
  })

  return (
    <div className="smoothie-time-container">
      <div className="smoothie-time-content">          
        <div className="smoothie-time-container-title">          
          <h2>Smoothie Time for maximizing the benefits from your {COMPANY} set</h2>
          {
            screenWidth < 748 
              ? <p>Don't forget to write down the smoothie recipe that was chosen especially for you.</p>
              : false
          }
        </div>
        <div className="smoothie-time-main-container">
          <div className="smoothie-time-main-container-left">
            {
              screenWidth > 748 
              ? <p>Don't forget to write down the smoothie recipe that was chosen especially for you.</p>
              : false
            }
            <div className="nutritional-benefits-container">
              <h4>Nutritional benefits:</h4>
              <ul>
                {nutritionalBenefitsList}
              </ul>
            </div>
          </div>
          <div className="smoothie-time-main-container-right">
            <h4>Contents:</h4>
            <div className="ingredient-images-container">
              {ingredientImgComponents}
            </div>
          </div>
      </div>
      </div>
    </div>
  )
}