import { useState, useEffect } from "react";
import ButtonsBlock from "../ButtonsBlock/ButtonsBlock";

export default function InputQuestion({ props }) {
  const [isInputFilled, setIsInputFilled] = useState(true);
  const [value, setValue] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    if (value.length > 0) {
      setIsInputFilled(true);
      props.nextInnerStep(props.nextQuestion);
    } else {
      setIsInputFilled(false);
    }
  }

  function handleChangeInput(e) {
    setValue(e.target.value);
    props.onInputChange(e);
  }

  useEffect(() => {
    // console.log(isNameFilled)
  }, [isInputFilled, value]);

  return (
    <form
      className="input-question question-inner"
      onSubmit={handleSubmit}
    >
      <div className="input-question-input-box">
        <input
          className='input-question-box'
          placeholder={props.placeholder}
          maxLength={props.length[1]}
          onChange={handleChangeInput}
          // required={props.isRequired}
          autoFocus />
        {/* <span className="input-box_error" style={{opacity: isInputFilled && 0}}>{props.emptyInputText}</span> */}
        {(!isInputFilled && props.isRequired) && (<span className="input-box_error">{props.emptyInputText}</span>)}
      </div>

      <ButtonsBlock
        previousInnerStep={props.previousInnerStep}
        previousQuestion={props.previousQuestion}
        nextType='submit'
        buttonText={props.buttonText}
      />
    </form>
  )
}