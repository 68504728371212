import "normalize.css";
import "./index.css";
import { useState, useEffect } from "react";
import Main from "./components/Main/Main";
import Header from "./components/Header/Header";
import SmoothieStep from "./components/SmoothieStep/SmoothieStep";
import PersonalRecommendation from "./components/PersonalRecommendation/PersonalRecommendation";
import TopIngredients from "./components/TopIngredients/TopIngredients";
import ScoreCalculatedTopIngredients from "./components/TopIngredients/ScoreCalculatedTopIngredients";
import UserScores from "./data/UserScores.json";
import Responses from "./data/Responses.json";
import axios from "axios";
import * as api from "./utils/api";
import useExitPageWarning from "./utils/useExitPageWarning.utils";
//TODO when going on deploy change the url of the request

function App() {
  const [isOnStep, setIsOnStep] = useState(1);
  const [isButtonActive, setIsButtonActive] = useState(true);
  const [userScores, setUserScores] = useState(UserScores);
  const [responses, setResponses] = useState(Responses);
  const [recommendedIngredients, setRecommendedIngredients] = useState([]); //A list of 3 recommended ingredients objects.
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [personalSentences, setPersonalSentences] = useState([]);
  const [bestMatchTags, setBestMatchTags] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [smoothieIngredients, setSmoothieIngredients] = useState([]);
  const [smoothie, setSmoothie] = useState();
  const [isCalculatedTopIngredients, setIsCalculatedTopIngredients] = useState(false);
  const [isResultReady, setIsResultReady] = useState(false);
  const [questionsCounter, setQuestionsCounter] = useState(0);

  const [blocksLength, setBlocksLength] = useState({
    block1: 1,
    block2: 4,
    block3: 6,
  }); //Mi

  /* Get all the ingredients for top ingredients for top-ingredients 
   element on the first screen*/
  useEffect(() => {
    async function fetchData() {
      const data = await api.getAllIngredients();
      setRecommendedIngredients(data);
    };
    fetchData();
  }, []);

  /* create warning if user tries to leave the page */
  useExitPageWarning();

  const displaySmoothiePage = () => {
    setIsOnStep(2);
    getProducts();
  };

  const displayPersonalRecommendation = () => {
    setIsOnStep(3);
  };

  const closeTheQuiz = () => {
    setIsOnStep(null);
    setIsButtonActive(false);
  };

  const nextStep = () => {
    setIsOnStep(isOnStep + 1);
  };

  const previousStep = () => {
    setIsOnStep(isOnStep - 1);
  };

  const nextInnerStep = (questionNumber) => {
    setQuestionsCounter(questionNumber);
    if (questionNumber >= 2) {
      setIsCalculatedTopIngredients(true);
      getRecommendedIngredients(3);
    }
    const topOfPage = document.getElementById("top-of-questions-container");
    topOfPage.scrollIntoView({ behavior: 'smooth'});
  };

  const lastStep = () => {
    nextStep();
    getSmoothies();
    displaySmoothiePage();
  };

  const previousInnerStep = (previousQuestion) => {
    if (previousQuestion === 1) {
      setIsCalculatedTopIngredients(false);
      async function fetchData() {
        const data = await api.getAllIngredients();
        setRecommendedIngredients(data);
      };
      fetchData();
    }
    setQuestionsCounter(previousQuestion);
    const topOfPage = document.getElementById("top-of-questions-container");
    topOfPage.scrollIntoView({ behavior: 'smooth'});
  };

  const updateBlocksLength = (updatedBlocksLength) => {
    setBlocksLength(updatedBlocksLength);
  };

  function updateUserResponses(questionNumber, newAnswer) {
    const updatedResponses = [...responses];

    if (questionNumber >= 0 && questionNumber < responses.length) {
      updatedResponses[questionNumber].answer = newAnswer;
      setResponses(updatedResponses);
    }
  }

  function handleSaveUserResponsesMulti(questionNumber, responseText, action) {
    let newArray = [];
    let preArray = responses[questionNumber].answer;

    // if array exists make a copy of it
    if (Array.isArray(preArray)) {
      newArray = [...preArray];
    }

    // add to array if item is checked
    if (action === "push") {
      newArray.push(responseText);
    }
    // remove from array if item is unchecked
    else {
      const index = newArray.indexOf(responseText);
      newArray.splice(index, 1);
    }

    updateUserResponses(questionNumber, newArray);
  }

  /* function will update the user's scores after each response to a question.
    pass an object with category-score pairs to the updateUserScores function. 
    Each property in the updates object represents a category, 
    and its corresponding value represents the updated score for that category */
  function updateUserScores(updates) {
    setUserScores({ ...userScores, ...updates });
  }

  /* Getting a recommendation on ingredients - a reply from the Backend
      returning array of 3 ingredient. */
  async function getRecommendedIngredients(step) {
    const data = await api.getRecommendedIngredients(userScores);
    setRecommendedIngredients(data);
  }

  function getProducts() {
    axios
      .post("/recommend-products", { userScores, responses })
      .then((response) => {
        console.log('Response from API call:', response.data);
        setRecommendedProducts(response.data.dataToClient);
        // setPersonalSentences(response.data.personalSentencesCompatibility);
        // setBestMatchTags(response.data.bestMatch);
        setRecommendedIngredients(response.data.displayIngredients);
        setTimeout(() => {
          setIsResultReady(true); //for loader on smoothie step
        }, 3000);
      })
      .catch((error) => "error in getIngredients: " + error.message);
  }

  function getSmoothies() {
    axios
      .post("/recommend-smoothies", userScores)
      .then((response) => {
        setSmoothieIngredients(response.data.smoothieIngredients);
        setSmoothie(response.data.weightedAverageSmoothies);
      })
      .catch((error) => "error in getIngredients: " + error.message);
  }

  const getEmail = (e) => {
    setEmail(e.target.value);

    setResponses({
      ...responses,
      email: e.target.value,
    });
  };

  const getUserName = (e) => {
    setName(e.target.value);
    updateUserResponses(0, e.target.value);
  };

  useEffect(() => { }, [smoothie, smoothieIngredients]);

  useEffect(() => {
    //console.log(responses);
  }, [responses]);

  useEffect(() => {
    //console.warn(userScores)
    // console.log(
    //   `recommended ingredients for the user: ${recommendedIngredients}`
    // );
    // console.log(`userScores Seborrhea: ${userScores.Seborrhea}`);
    // console.log(`userScores Photoaging: ${userScores.Photoaging}`);
    // console.log(`userScores Aging: ${userScores.Aging}`);
    // console.log(`userScores Oil: ${userScores['Oily Skin']}`);
    // console.log(`userScores Dry: ${userScores['Dry Skin']}`);
    // console.log(`userScores Sensitive Skin: ${userScores['Sensitive Skin']}`);
    // console.log(`userScores Psoriasis: ${userScores['Psoriasis']}`);
    // console.log(
    //   `userScores Atopic Dermatitis/ Eczema: ${userScores['Atopic Dermatitis/ Eczema']}`
    // );
    // console.log(`userScores Pigmentation: ${userScores.Pigmentation}`);
    // console.log(`userScores Rosacea: ${userScores.Rosacea}`);
    // console.log(`userScores Acne: ${userScores.Acne}`);
  }, [recommendedIngredients, userScores]);

  useEffect(() => { }, [email]);

  useEffect(() => { }, [blocksLength]);

  useEffect(() => { }, [questionsCounter]);

  return (
    <div className="App">
      {isOnStep === 1
        && <div className="page">
          <Header
            isOnStep={isOnStep}
            name={name}
            isCalculatedTopIngredients={isCalculatedTopIngredients}
            recommendedIngredients={recommendedIngredients}
            getRecommendedIngredients={getRecommendedIngredients}
            questionsCounter={questionsCounter}
            blocksLength={blocksLength}
            responses={responses}
          />

          <Main
            closeTheQuiz={closeTheQuiz}
            questionsCounter={questionsCounter}
            blocksLength={blocksLength}
            updateBlocksLength={updateBlocksLength}
            nextStep={nextStep}
            previousInnerStep={previousInnerStep}
            isOnStep={isOnStep}
            nextInnerStep={nextInnerStep}
            userScores={userScores}
            updateUserScores={updateUserScores}
            responses={responses}
            updateUserResponses={updateUserResponses}
            recommendedIngredients={recommendedIngredients}
            name={name}
            getUserName={getUserName}
            handleSaveUserResponsesMulti={handleSaveUserResponsesMulti}
            getRecommendedIngredients={getRecommendedIngredients}
            lastStep={lastStep}
          />
          {questionsCounter > 1
            ? (
              <ScoreCalculatedTopIngredients
                recommendedIngredients={recommendedIngredients}
                isOnStep={isOnStep}
                questionsCounter={questionsCounter}
              />
            ) : (
              <TopIngredients
                recommendedIngredients={recommendedIngredients}
                questionsCounter={questionsCounter} />
            )}
        </div>}

      {isOnStep === 2
        && <SmoothieStep
          closeTheQuiz={closeTheQuiz}
          isOnStep={isOnStep}
          onClick={displayPersonalRecommendation}
          isResultReady={isResultReady}
          smoothieIngredients={smoothieIngredients}
          smoothie={smoothie}
        />}

      {isOnStep === 3
        && <PersonalRecommendation
          isOnStep={isOnStep}
          recommendedIngredients={recommendedIngredients}
          recommendedProducts={recommendedProducts}
          // personalSentences={personalSentences}
          // bestMatchTags={bestMatchTags}
          smoothieIngredients={smoothieIngredients}
          smoothie={smoothie}
          name={name}
        />}
    </div>
  );
}

export default App;
