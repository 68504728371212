import IngredientImage from "../IngredientImage/IngredientImage";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import './IngredientCarousel.css';

import swipeLeft from '../../icons/swipe-left.svg';
import swipeRight from '../../icons/swipe-right.svg';

const ImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/kamedising/{id}.jpg`;

export const IngredientCarousel = (props) => {
    return (
        <div className="ingredient-carousel">
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'3'}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                }}
                initialSlide='1'
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="swiper_container">
                {props.recommendedIngredients.map((ingredient) => {
                    const { ID, Name, "Short definition": shortDefinition, "Kamedis Description": kamedis_description } = ingredient;

                    return(
                        <SwiperSlide key={ID}>
                           <IngredientImage
                                width='154px'
                                height='154px'
                                src={ImgUrl.replace('{id}', ID)}
                                name={Name}
                            />
                            <div className="ingredient-carousel-text">
                                <p className="ingredient-info-description">
                                    <span className="ingredient-info-title">{Name} </span>
                                    {shortDefinition}
                                </p>
                                <p className="ingredient-benefits">{kamedis_description}</p>
                            </div>                        
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <div className="ingredient-carousel-swipe-container">
                <img src={swipeLeft} alt="swipe left" className="swipe-left" />
                <span>Swipe</span>
                <img src={swipeRight} alt="swipe right" className="swipe-right" />
            </div>
        </div>
    );
}