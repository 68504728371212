import Button from "../Button/Button";

import { calculateDiscount } from "../../utils/CartUtils";

const ImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/kamedisprod/{id}.jpg`;

export default function RecommendedSet(props) {
  const { productPrices } = props;

  let imgProductComponents = [];
  if (props.imagesSetProducts !== undefined) {
    imgProductComponents = props.imagesSetProducts.map((ID) => (
      <div key={ID} onClick={() => props.scrollToProduct(ID)}>
        <img src={ImgUrl.replace("{id}", ID)} alt="img-recommended-product" />
      </div>
    ));
  }

  const handleClick = () => {
    props.setItemCount((prevCount) => prevCount + 4);
    const deductDiscount = calculateDiscount(productPrices, props.discount);
    console.log("deductDiscount", deductDiscount);
    props.setPrice((prevPrice) => prevPrice + productPrices - deductDiscount);
  };

  return (
    <div className={props.className}>
      <div className="recommended-products-set-images">
        {imgProductComponents}
      </div>
      <div className="recommended-products-set-bottom">
        <Button
          className="button_recommended-set"
          buttonText="Add the set to cart"
          onClick={handleClick}
        />
        <p>Your 30% discount will be applied automatically</p>
      </div>
    </div>
  );
}
