import useScreenSize from "../../utils/useScreenSize";
import Question from "../Question/Question";
import { useState } from "react";
import {
  marks,
  GreenRedSliderStyles,
  GreenBlueSliderStyles,
} from "../../utils/constants";

export default function Step1(props) {
  // saved answers to show additional questions
  const [skinConditions, setSkinConditions] = useState([]);
  const [skinIllness, setSkinIllness] = useState([]);
  const [acneIssues, setAcneIssues] = useState([]);

  /* function for update amount of questions in the block for the progress bar painting:
  think how to implement it better */
  function updateBlockLength(amountOfAddedQuestions) {
    if (props.blocksLength.block1 < 12) {
      const updatedBlocksLength = props.blocksLength;
      updatedBlocksLength.block1 = props.blocksLength.block1 + amountOfAddedQuestions;
      props.updateBlocksLength(updatedBlocksLength);
    }
  }

  return (
    <>
      <Question
        questionNumber={0}
        nextQuestion={1}
        questions={props.responses}
        questionType='input'
        questionTitle={'Please tell us your name.'}
        placeholder={'Name or nickname'}
        buttonText={'Next'}
        nextInnerStep={props.nextInnerStep}
        onInputChange={props.getUserName}
        isRequired={true}
        emptyInputText={'Please enter your name'}
        length={[2, 15]}
        questionsCounter={props.questionsCounter}
      />

      <Question
        questionNumber={1}
        previousQuestion={0}
        nextQuestion={
          skinConditions.includes('Sensitive/irritated skin')
            ? 2
            : skinConditions.includes('Acneic skin issues')
              ? 4
              : skinConditions.includes('Pigmentation changes')
                ? 12
                : skinConditions.includes('Scalp issues')
                  ? 14
                  : 15
        }
        questions={props.responses}
        subtitle={props.responses[1].sentence}
        responses={props.responses[1].answer}
        questionType='multi'
        updateBlockLength={updateBlockLength}
        updateCategory={(responses) => {
          setSkinConditions(responses);
        }}
        questionTitle={"What are your main skin concerns? Choose all that\u00A0apply."}
        width={'712px'}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        handleMultiResponses={props.handleSaveUserResponsesMulti}
        questionsCounter={props.questionsCounter} />

      {/* For 'Sensitive/irritated skin' option */}
      {skinConditions.includes('Sensitive/irritated skin') &&
        <>
          <Question
            questionNumber={2}
            previousQuestion={1}
            nextQuestion={
              skinIllness.length === 0
                ? 3
                : skinConditions.includes('Acneic skin issues')
                  ? 4
                  : skinConditions.includes('Pigmentation changes')
                    ? 12
                    : skinConditions.includes('Scalp issues')
                      ? 14
                      : 15
            }
            questions={props.responses}
            responses={props.responses[2].answer}
            width='703px'
            buttonInitialText='not sure'
            questionType='multi'
            splitSubtitleBeginning={props.responses[2].sentence[0]}
            splitSubtitleCondition={props.responses[2].sentence[1]}
            splitSubtitleEnding={props.responses[2].sentence[2]}
            highlightColor='peach'
            updateCategory={(responses) => {
              setSkinIllness(responses);
            }}
            previousInnerStep={props.previousInnerStep}
            questionTitle={'Choose all the options that apply to\u00A0your\u00A0skin:'}
            nextInnerStep={props.nextInnerStep}
            updateUserScores={props.updateUserScores}
            userScores={props.userScores}
            handleMultiResponses={props.handleSaveUserResponsesMulti}
            questionsCounter={props.questionsCounter} />

          {/* If nothing is chosen in the previous question */}
          {skinIllness.length === 0 &&
            <Question
              questionNumber={3}
              previousQuestion={2}
              nextQuestion={
                skinConditions.includes('Acneic skin issues')
                  ? 4
                  : skinConditions.includes('Pigmentation changes')
                    ? 12
                    : skinConditions.includes('Scalp issues')
                      ? 14
                      : 15
              }
              questions={props.responses}
              width='627px'
              responses={props.responses[3].answer}
              questionType='multi'
              splitSubtitleBeginning={props.responses[3].sentence[0]}
              splitSubtitleCondition={props.responses[3].sentence[1]}
              splitSubtitleEnding={props.responses[3].sentence[2]}
              highlightColor='peach'
              previousInnerStep={props.previousInnerStep}
              questionTitle={'Choose all the options that apply to\u00A0your\u00A0skin:'}
              nextInnerStep={props.nextInnerStep}
              updateUserScores={props.updateUserScores}
              userScores={props.userScores}
              handleMultiResponses={props.handleSaveUserResponsesMulti}
              questionsCounter={props.questionsCounter} />
          }
        </>
      }

      {/* For 'Acneic skin issues' option */}
      {skinConditions.includes('Acneic skin issues') &&
        <>
          <Question
            questionNumber={4}
            previousQuestion={
              skinConditions.includes('Sensitive/irritated skin')
                ? skinIllness.length === 0
                  ? 3
                  : 2
                : 1
            }
            nextQuestion={
              acneIssues.includes('Acne breakouts')
                ? 5
                : acneIssues.includes('Pimples')
                  ? 7
                  : (acneIssues.includes('Black-heads') || acneIssues.includes('White-heads'))
                    ? 8
                    : skinConditions.includes('Pigmentation changes')
                      ? 12
                      : skinConditions.includes('Scalp issues')
                        ? 14
                        : 15
            }
            questions={props.responses}
            responses={props.responses[4].answer}
            questionType='multi'
            width='635px'
            splitSubtitleBeginning={props.responses[4].sentence[0]}
            splitSubtitleCondition={props.responses[4].sentence[1]}
            splitSubtitleEnding={props.responses[4].sentence[2]}
            highlightColor='blue'
            updateBlockLength={updateBlockLength}
            updateCategory={(responses) => {
              setAcneIssues(responses);
            }}
            previousInnerStep={props.previousInnerStep}
            questionTitle={'Choose all the options that apply to\u00A0your\u00A0skin:'}
            nextInnerStep={props.nextInnerStep}
            updateUserScores={props.updateUserScores}
            userScores={props.userScores}
            handleMultiResponses={props.handleSaveUserResponsesMulti}
            questionsCounter={props.questionsCounter} />

          {/* For 'Acne breakouts' options */}
          {acneIssues.includes('Acne breakouts') &&
            <>
              <Question
                questionNumber={5}
                previousQuestion={4}
                nextQuestion={6}
                questionsCounter={props.questionsCounter}
                responses={props.responses[5].answer}
                questions={props.responses}
                questionType='slider'
                subtitle={props.responses[5].sentence}
                questionTitle={"How often do you have breakouts?"}
                sliderTextLeft="Only on occasion"
                sliderTextRight="Always & constantly"
                sliderStyle={GreenRedSliderStyles}
                texts={2}
                marks={marks}
                defaultSliderValue={50}
                nextInnerStep={props.nextInnerStep}
                previousInnerStep={props.previousInnerStep}
                userScores={props.userScores}
                updateUserScores={props.updateUserScores}
                updateUserResponses={props.updateUserResponses}
                score={{
                  0: { Acne: 0 },
                  25: { Acne: 0 },
                  50: { Acne: 1 },
                  75: { Acne: 2 },
                  100: { Acne: 3 }
                }} />

              <Question
                questionNumber={6}
                previousQuestion={5}
                nextQuestion={acneIssues.includes('Pimples') ? 7 : 8}
                questionsCounter={props.questionsCounter}
                responses={props.responses[6].answer}
                questions={props.responses}
                questionType='slider'
                subtitle={props.responses[6].sentence}
                questionTitle={"How severe your acne breakouts usually manifest?"}
                sliderTextLeft="Mild"
                sliderTextRight="Severe"
                sliderTextCenter="Moderate"
                sliderStyle={GreenRedSliderStyles}
                texts={3}
                marks={marks}
                defaultSliderValue={50}
                nextInnerStep={props.nextInnerStep}
                previousInnerStep={props.previousInnerStep}
                userScores={props.userScores}
                updateUserScores={props.updateUserScores}
                updateUserResponses={props.updateUserResponses}
                score={{
                  0: { Acne: 0 },
                  25: { Acne: 0 },
                  50: { Acne: 1 },
                  75: { Acne: 2 },
                  100: { Acne: 3 }
                }} />
            </>
          }

          {/* For 'Pimples' options */}
          {acneIssues.includes('Pimples') &&
            <Question
              questionNumber={7}
              previousQuestion={acneIssues.includes('Acne breakouts') ? 6 : 4}
              nextQuestion={8}
              questionsCounter={props.questionsCounter}
              responses={props.responses[7].answer}
              questions={props.responses}
              questionType='slider'
              subtitle={props.responses[7].sentence}
              questionTitle={"How often do you have pimples?"}
              sliderTextLeft="Only on occasion"
              sliderTextRight="Always & constantly"
              sliderStyle={GreenRedSliderStyles}
              texts={2}
              marks={marks}
              defaultSliderValue={50}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              score={{
                0: { Acne: 0 },
                25: { Acne: 0 },
                50: { Acne: 1 },
                75: { Acne: 2 },
                100: { Acne: 3 }
              }} />
          }

          <Question
            questionNumber={8}
            previousQuestion={acneIssues.includes('Pimples')
              ? 7
              : acneIssues.includes('Acne breakouts')
                ? 6
                : 4}
            nextQuestion={9}
            questionsCounter={props.questionsCounter}
            responses={props.responses[8].answer}
            questions={props.responses}
            questionType='multi'
            subtitle={props.responses[8].sentence}
            width={'540px'}
            questionTitle={"Which factors seem to effect the\u00A0changes?"}
            nextInnerStep={props.nextInnerStep}
            previousInnerStep={props.previousInnerStep}
            userScores={props.userScores}
            updateUserScores={props.updateUserScores}
            handleMultiResponses={props.handleSaveUserResponsesMulti} />

          <Question
            questionNumber={9}
            previousQuestion={8}
            nextQuestion={10}
            questionsCounter={props.questionsCounter}
            questions={props.responses}
            questionType='single'
            subtitle={props.responses[9].sentence}
            getRecommendedIngredients={props.getRecommendedIngredients}
            topIngredientsNumber={3}
            questionTitle={"How does your skin usually react to skin products made for acne-prone\u00A0skin?"}
            nextInnerStep={props.nextInnerStep}
            previousInnerStep={props.previousInnerStep}
            userScores={props.userScores}
            updateUserScores={props.updateUserScores}
            updateUserResponses={props.updateUserResponses} />

          <Question
            questionNumber={10}
            previousQuestion={9}
            nextQuestion={11}
            questionsCounter={props.questionsCounter}
            questions={props.responses}
            questionType='single'
            getRecommendedIngredients={props.getRecommendedIngredients}
            topIngredientsNumber={3}
            questionTitle={"Do you use retinols or retinoids in your daily skincare routine?"}
            subtitle={props.responses[10].sentence}
            nextInnerStep={props.nextInnerStep}
            previousInnerStep={props.previousInnerStep}
            userScores={props.userScores}
            updateUserScores={props.updateUserScores}
            updateUserResponses={props.updateUserResponses} />

          <Question
            questionNumber={11}
            previousQuestion={10}
            nextQuestion={skinConditions.includes('Pigmentation changes')
              ? 12
              : skinConditions.includes('Scalp issues')
                ? 14
                : 15}
            questionsCounter={props.questionsCounter}
            questions={props.responses}
            questionType='single'
            getRecommendedIngredients={props.getRecommendedIngredients}
            topIngredientsNumber={3}
            questionTitle={"Are you receiving treatment with prescription medications for\u00A0acne?"}
            subtitle={props.responses[11].sentence}
            nextInnerStep={props.nextInnerStep}
            previousInnerStep={props.previousInnerStep}
            userScores={props.userScores}
            updateUserScores={props.updateUserScores}
            updateUserResponses={props.updateUserResponses} />
        </>
      }

      {/* For 'Pigmentation changes' option */}
      {skinConditions.includes('Pigmentation changes') &&
        <>
          <Question
            questionNumber={12}
            previousQuestion=
            {(acneIssues.includes('Pimples')
              || acneIssues.includes('White-heads')
              || acneIssues.includes('Black-heads')
              || acneIssues.includes('Acne breakouts'))
              ? 11
              : skinConditions.includes('Acneic skin issues')
                ? 4
                : (skinConditions.includes('Sensitive/irritated skin') && skinIllness.length === 0)
                  ? 3
                  : skinConditions.includes('Sensitive/irritated skin')
                    ? 2
                    : 1
            }
            nextQuestion={13}
            questionsCounter={props.questionsCounter}
            questions={props.responses}
            questionType='single'
            getRecommendedIngredients={props.getRecommendedIngredients}
            topIngredientsNumber={3}
            questionTitle={"Are the pigmentations issues more prominent in the face, neck and hands?"}
            splitSubtitleBeginning={props.responses[12].sentence[0]}
            splitSubtitleCondition={props.responses[12].sentence[1]}
            splitSubtitleEnding={props.responses[12].sentence[2]}
            highlightColor='purple'
            nextInnerStep={props.nextInnerStep}
            previousInnerStep={props.previousInnerStep}
            userScores={props.userScores}
            updateUserScores={props.updateUserScores}
            updateUserResponses={props.updateUserResponses} />

          <Question
            questionNumber={13}
            previousQuestion={12}
            nextQuestion={skinConditions.includes('Scalp issues') ? 14 : 15}
            questionsCounter={props.questionsCounter}
            questions={props.responses}
            questionType='multi'
            subtitle={props.responses[13].sentence}
            width={'505px'}
            questionTitle={"Which factors seem to affect the appearance of\u00A0the\u00A0spots?"}
            nextInnerStep={props.nextInnerStep}
            previousInnerStep={props.previousInnerStep}
            userScores={props.userScores}
            updateUserScores={props.updateUserScores}
            handleMultiResponses={props.handleSaveUserResponsesMulti} />
        </>
      }

      {/* For 'Scalp issues' option */}
      {skinConditions.includes('Scalp issues') &&
        <Question
          questionNumber={14}
          previousQuestion=
          {skinConditions.includes('Pigmentation changes')
            ? 13
            : (acneIssues.includes('Pimples')
              || acneIssues.includes('White-heads')
              || acneIssues.includes('Black-heads')
              || acneIssues.includes('Acne breakouts'))
              ? 11
              : skinConditions.includes('Acneic skin issues')
                ? 4
                : (skinConditions.includes('Sensitive/irritated skin') && skinIllness.length === 0)
                  ? 3
                  : skinConditions.includes('Sensitive/irritated skin')
                    ? 2
                    : 1
          }
          nextQuestion={15}
          questionsCounter={props.questionsCounter}
          questions={props.responses}
          questionType='multi'
          width={'497px'}
          splitSubtitleBeginning={props.responses[14].sentence[0]}
          splitSubtitleCondition={props.responses[14].sentence[1]}
          splitSubtitleEnding={props.responses[14].sentence[2]}
          highlightColor='peach'
          previousInnerStep={props.previousInnerStep}
          questionTitle={'Choose all the options that apply to\u00A0your\u00A0skin:'}
          nextInnerStep={props.nextInnerStep}
          updateUserScores={props.updateUserScores}
          userScores={props.userScores}
          handleMultiResponses={props.handleSaveUserResponsesMulti} />
      }

      <Question
        questionNumber={15}
        previousQuestion={skinConditions.includes('Scalp issues')
          ? 14
          : skinConditions.includes('Pigmentation changes')
            ? 13
            : (acneIssues.includes('Pimples')
              || acneIssues.includes('White-heads')
              || acneIssues.includes('Black-heads')
              || acneIssues.includes('Acne breakouts'))
              ? 11
              : skinConditions.includes('Acneic skin issues')
                ? 4
                : (skinConditions.includes('Sensitive/irritated skin') && skinIllness.length === 0)
                  ? 3
                  : skinConditions.includes('Sensitive/irritated skin')
                    ? 2
                    : 1
        }
        nextQuestion={16}
        questionsCounter={props.questionsCounter}
        questionType='single'
        subtitle={props.responses[15].sentence}
        questionTitle={"Does your skin usually feel drier in\u00A0the\u00A0winter?"}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses} />

      <Question
        questionNumber={16}
        previousQuestion={15}
        nextQuestion={17}
        questionsCounter={props.questionsCounter}
        questionType='slider'
        subtitle={props.responses[16].sentence}
        questionTitle={"How does your skin react to\u00A0sun?"}
        sliderTextLeft="My skin burns easily and hardly tans"
        sliderTextRight="My skin hardly gets sunburned"
        sliderStyle={GreenBlueSliderStyles}
        texts={2}
        marks={marks}
        defaultSliderValue={50}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses}
        score={{
          0: { Photoaging: 3 },
          25: { Photoaging: 2 },
          50: { Photoaging: 1 },
          75: { Photoaging: 0 },
          100: { Photoaging: 0 }
        }} />

      <Question
        questionNumber={17}
        previousQuestion={16}
        nextQuestion={18}
        questionsCounter={props.questionsCounter}
        questionType='slider'
        subtitle={props.responses[17].sentence}
        questionTitle={"How does your skin feel after cleaning and before moisturizing?"}
        sliderTextLeft="Tight & dry"
        sliderTextCenter="Comfortable"
        sliderTextRight="Still greasy & shiny"
        sliderStyle={GreenBlueSliderStyles}
        texts={3}
        marks={marks}
        defaultSliderValue={50}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses}
        score={{
          0: { 'Dry Skin': 2, 'Oily Skin': 0 },
          25: { 'Dry Skin': 1, 'Oily Skin': 0 },
          50: { 'Dry Skin': 0, 'Oily Skin': 0 },
          75: { 'Dry Skin': 0, 'Oily Skin': 1 },
          100: { 'Dry Skin': 0, 'Oily Skin': 2 }
        }} />

      <Question
        questionNumber={18}
        previousQuestion={17}
        nextQuestion={19}
        questionsCounter={props.questionsCounter}
        questionType='color'
        subtitle={props.responses[18].sentence}
        updateUserScores={props.updateUserScores}
        userScores={props.userScores}
        updateUserResponses={props.updateUserResponses}
        nextInnerStep={props.nextInnerStep}
        questionTitle={"How would you define your skin\u00A0tone?"}
        previousInnerStep={props.previousInnerStep}
      />
      <Question
        questionNumber={19}
        previousQuestion={18}
        nextQuestion={20}
        questionsCounter={props.questionsCounter}
        questionType='single'
        subtitle={props.responses[19].sentence}
        questionTitle={"How many hours do you spend under direct sun exposure per\u00A0day?"}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses} />
      <Question
        questionNumber={20}
        previousQuestion={19}
        nextQuestion={21}
        questionsCounter={props.questionsCounter}
        questionType='single'
        subtitle={props.responses[20].sentence}
        questionTitle={"How many glasses of water do you drink per\u00A0day?"}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses} />

      <Question
        questionNumber={21}
        previousQuestion={20}
        nextQuestion={22}
        questionsCounter={props.questionsCounter}
        questionType='single'
        subtitle={props.responses[21].sentence}
        questionTitle={"Do you smoke?"}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses} />

      <Question
        questionNumber={22}
        previousQuestion={21}
        nextQuestion={23}
        questionsCounter={props.questionsCounter}
        questionType='slider'
        subtitle={props.responses[22].sentence}
        questionTitle={"How stressed are you in your daily\u00A0life?"}
        sliderTextLeft="Not at all I’m super chill"
        sliderTextRight="All the time, life is hard"
        sliderStyle={GreenRedSliderStyles}
        texts={2}
        marks={marks}
        defaultSliderValue={50}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses}
        score={{
          0: { Photoaging: 0 },
          25: { Photoaging: 0 },
          50: { Photoaging: 1 },
          75: { Photoaging: 2 },
          100: { Photoaging: 3 }
        }} />

      <Question
        questionNumber={23}
        previousQuestion={22}
        nextQuestion={24}
        questionsCounter={props.questionsCounter}
        questionType='single'
        subtitle={props.responses[23].sentence}
        questionTitle={"Which gender do you identify\u00A0with?"}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses} />

      <Question
        questionNumber={24}
        previousQuestion={23}
        questionType='single'
        subtitle={props.responses[24].sentence}
        questionsCounter={props.questionsCounter}
        questionTitle={"What is your age group?"}
        nextInnerStep={props.lastStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses} />
    </>
  );
}
