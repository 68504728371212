import axios from "axios";

axios.defaults.baseURL = "https://api.kamedis.menow.ai/";

async function getAllIngredients() {
  try {
    const response = await axios.get("/product-scores/ingredients");
    return response.data;
  } catch (error) {
    console.log("error in getIngredients:" + error.message);
    return [];
  }
}

/* Getting a recommendation on ingredients - a reply from the Backend
    returning array of 3 ingredient. */
async function getRecommendedIngredients(userScores) {
  let stepp = 3;
  try {
    const response = await axios.post("/recommend-ingredients", {
      userScores,
      step: stepp,
    });
    return response.data;
  } catch (error) {
    console.log("error in getIngredients: " + error.message);
    return {};
  }
}

export { getAllIngredients, getRecommendedIngredients };
