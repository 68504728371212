export default function BackButton(props) {
  function handleClick() {
    if (props.previousInnerStep)
      props.previousInnerStep(props.previousQuestion);
  }

  return (
    <button
      className="question-back-button"
      type='button'
      onClick={handleClick} />
  )
}