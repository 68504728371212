import BestIngredients from "../BestIngredients/BestIngredients";
import RecommendedSet from "../RecommendedSet/RecommendedSet";
import RecommendedProduct from "../RecommendedProduct/RecommendedProduct";
import SmoothieTime from "../SmoothieTime/SmoothieTime";
import Cart from "../Cart/Cart";
import { COMPANY } from "../../utils/constants";
import { appendDiscount } from "../../utils/CartUtils";
import { useState, useEffect, useRef, useCallback } from "react";

// const productOrder = {
//   Wash: 1,
//   Cream: 2,
//   Scalp: 3,
//   Masks: 4,
//   other: 5,
// };

export default function PersonalRecommendation(props) {
  // console.log('rendering PersonnalRecommendation component');
  console.log("here  --->", props.recommendedIngredients);
  const recommendedProducts = props.recommendedProducts; // 5 recommended products
  const threeRecommendedIngredients = props.recommendedIngredients.slice(0, 3);
  const personalSentences = props.personalSentences; // 3 personalSentences for each product.
  const bestMatchSentences = props.bestMatchTags;
  // console.log("recommendedProducts", props.recommendedProducts);
  // console.log("personalSentences", personalSentences);
  // console.log("recommendedIngredients", props.recommendedIngredients);
  // console.log("smoothieIngredients", props.smoothieIngredients);
  // console.log("bestMatchSentences", props.bestMatchSentences);

  const [imagesSetProducts, setImagesSetProducts] = useState([]);
  const [sortedRecommendedProducts, setSortedRecommendedProducts] = useState(
    []
  );
  //store all of the recommended Products cart IDs
  const [cartID, setCartID] = useState([]);
  // products prices for recomnded set
  const [productPrices, setProductPrices] = useState(0);

  const [price, setPrice] = useState(0.0);
  const [itemCount, setItemCount] = useState(0);
  const [cartUrl, setCartUrl] = useState("https://www.kamedis.com/cart/");
  const [discount, setDiscount] = useState(30);

  // Makes sure top of page is in view when component first mounts
  useEffect(() => {
    const topOfPage = document.getElementById("root");
    topOfPage.scrollIntoView();
  }, []);

  // Once we recieve recommendedProducts from props, sort them based on our defined product order
  // useEffect(() => {
  //   if (recommendedProducts !== undefined) {
  //     const cartIDArray = recommendedProducts.map(
  //       (product) => product.Variant_ID
  //     );

  //     recommendedProducts.map((product) => {
  //       if (product.Price) {
  //         setProductPrices((prevPrice) => prevPrice + product.Price);
  //       }
  //     });
  //     // Sorting products based on last word in the category
  //     const sortedProducts = recommendedProducts.slice().sort((a, b) => {
  //       return (
  //         productOrder[a.Category.split(" ").at(-1)] -
  //         productOrder[b.Category.split(" ").at(-1)]
  //       );
  //     });
  //     setSortedRecommendedProducts(sortedProducts);
  //     // console.log("sortedProducts", sortedProducts);

  //     setCartID(cartIDArray);
  //   }
  // }, [recommendedProducts]);

  // Once products are sorted, get imagesID for every product
  useEffect(() => {
    const imagesID = recommendedProducts.map((product) =>
      product.productsForDB.ID.split("\n")
    );

    setImagesSetProducts(imagesID);
  }, [recommendedProducts]);

  const productRefs = useRef({});
  const addToRefs = useCallback((el, id) => {
    productRefs.current[id] = el;
  }, []);

  const scrollToProduct = (productID) => {
    // console.log('scrollIntoView:', productRefs.current[productID]);
    productRefs.current?.[productID]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const goToCheckout = (e) => {
    e.stopPropagation();
    //window.open(appendDiscount(discount, cartUrl));
    window.open(
      "https://www.kamedis.co.il/cart/34139325431939:1,34139325038723:1,34139325137027:1?discount=15off"
    );
  };

  const productComponents = recommendedProducts.map((product, index) => {
    return (
      <div
        key={product.productsForDB.ID}
        ref={(el) => {
          addToRefs(el, product.productsForDB.ID);
        }}
      >
        <RecommendedProduct
          key={index}
          productIndexNumber={index + 1}
          recommendedProduct={product}
          // personalSentences={personalSentences[index]}
          // bestMatchTags={bestMatchSentences[index]}
          setItemCount={setItemCount}
          setPrice={setPrice}
          discount={discount}
        />
      </div>
    );
  });

  if (props.isOnStep === 3) {
    return (
      <div
        className="personal-recommendation-container"
        id="personal-recommendation"
      >
        <div className="personal-recommendation-title">
          <p className="personal-recommendation-title-text">
            Congratulations, {props.name}! Your personal recommendations are
            ready!
          </p>
        </div>
        <div className="best-ingredients-container">
          <Cart
            price={price}
            itemCount={itemCount}
            goToCheckout={goToCheckout}
            cartUrl={cartUrl}
          />
          <BestIngredients
            recommendedIngredients={threeRecommendedIngredients}
          />
        </div>
        <div className="recommended-products-container">
          <div className="recommended-products-top-container">
            <h3>Your recommended {COMPANY} products are here!</h3>
            <p>
              Try these clean, high-performance products for healthy, glowing
              skin.
            </p>
            <RecommendedSet
              className="recommended-products-set-container"
              imagesSetProducts={imagesSetProducts}
              scrollToProduct={scrollToProduct}
              setItemCount={setItemCount}
              productPrices={productPrices}
              setPrice={setPrice}
              discount={discount}
            />
          </div>
          <div className="recommended-products-details-container">
            {productComponents}
          </div>
          <RecommendedSet
            className="recommended-products-set-container recommended-products-set-container-bottom"
            imagesSetProducts={imagesSetProducts}
            scrollToProduct={scrollToProduct}
            setItemCount={setItemCount}
            productPrices={productPrices}
            setPrice={setPrice}
            discount={discount}
          />
        </div>
        <SmoothieTime smoothieIngredients={props.smoothieIngredients} />
      </div>
    );
  }
}
