import {
  buildCartLinkSingleItem,
  calculateDiscount,
} from "../../utils/CartUtils";
import useScreenSize from "../../utils/useScreenSize";
import { wordBreaker } from "../../utils/results-page.utils";
import Button from "../Button/Button";

const ProductImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/kamedisprod/{id}.jpg`;
const IngredientImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/kamedising/{id}.jpg`;

const titleList = {
  1: "Cleansing routine with",
  2: "Daily care with",
  3: "Extra care with",
  4: "Extra care with",
  5: "Extra care with",
};

export default function RecommendedProduct(props) {
  // console.log('props', props);
  const {
    recommendedProduct,
    productIndexNumber,
    personalSentences,
    bestMatchTags,
  } = props;
  const { Usage, ID, Description, Price, Series, Title, stepTitle } = recommendedProduct.productsForDB;
  // const stepTitle = `${productIndexNumber}. ${titleList[productIndexNumber]} ${Series} collection`;

  const [screenWidth] = useScreenSize();

  const usageInstructionsArray = Usage.split("\n");
  const usageInstructionsList = usageInstructionsArray.map(
    (instruction, index) => <li key={index}>{instruction}</li>
  );

  const mainIngredientsList = recommendedProduct.productsForDB.ingredientsIDForProduct.map(
    (ingredient, index) => {
      const formattedIngredientName =
        screenWidth <= 820 ? wordBreaker(ingredient.name, 10) : ingredient.name;

      return (
        <div key={index} className="product-main-ingredient-container">
          <img src={IngredientImgUrl.replace("{id}", ingredient.id)} alt="" />
          <p>{formattedIngredientName}</p>
        </div>
      );
    }
  );

  const productImpactList = recommendedProduct.personalSentencesCompatibility.map((sentence, index) => (
    <div key={index} className="tag-container">
      <p>{sentence}</p>
    </div>
  ));

  const BestMatchList = recommendedProduct.bestMatch.map((tag, index) => (
    <div key={index} className="tag-container">
      <p>{tag}</p>
    </div>
  ));

  const handleClick = () => {
    props.setItemCount((prevCount) => prevCount + 1);
    const deductDiscount = calculateDiscount(Price, props.discount);
    console.log("deductDiscount", deductDiscount);
    props.setPrice((prevPrice) => prevPrice + Price - deductDiscount);
  };

  return (
    <div className={`product-${productIndexNumber}`}>
      <div
        className={`recommended-products-details-title title-${productIndexNumber}`}
      >
        <p>{`${productIndexNumber}. ${stepTitle}`}</p>
      </div>
      <div className="recommended-products-details-main-container">
        <div className="recommended-products-details-main-left">
          <h3>{Series}</h3>
          <p>{Title}</p>
          <img src={ProductImgUrl.replace("{id}", ID)} alt={props.alt} />
        </div>
        <div className="recommended-products-details-main-right">
          <div className="recommended-products-details-main-ingredients-container">
            <h4>Main Ingredients:</h4>
            <div className="product-main-ingredients-container">
              {mainIngredientsList}
            </div>
          </div>
          <div className="recommended-products-details-personal-compatibility-container">
            <h4>Personal compatibility:</h4>
            <p>{Description}</p>
            <div className="bottom-container">
              <div className="relevant-products-container">
                <h5>Relevant product impacts:</h5>
                <div className="tags-container">{productImpactList}</div>
              </div>
              <div className="best-match-container">
                <h5>Best match for you as you have:</h5>
                <div className="tags-container">{BestMatchList}</div>
              </div>
            </div>
          </div>
          <div className="recommended-products-details-how-to-use-container">
            <h4>How to use:</h4>
            <ol>{usageInstructionsList}</ol>
          </div>
          <div className="recommended-products-details-add-to-cart-container">
            <Button
              className="button_add-to-cart"
              buttonText={`₪${Price} | Add to cart`}
              onClick={handleClick}
            />
            <div className="text-to-the-right-of-button">
              <p>
                Choose <span>{Title}</span> with 30% discount
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
