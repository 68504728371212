import Step1 from "../Step1/Step1";

export default function Main(props) {
  return (
    <div className="main">
      <button className="main-close-btn" onClick={props.closeTheQuiz} />
      {props.isOnStep === 1 && (
        <div
          className="main-questions-container"
          id="top-of-questions-container"
        >
          <Step1
            questionsCounter={props.questionsCounter}
            blocksLength={props.blocksLength}
            updateBlocksLength={props.updateBlocksLength}
            getRecommendedIngredients={props.getRecommendedIngredients}
            nextStep={props.intermediateResult}
            previousInnerStep={props.previousInnerStep}
            nextInnerStep={props.nextInnerStep}
            userScores={props.userScores}
            updateUserScores={props.updateUserScores}
            responses={props.responses}
            updateUserResponses={props.updateUserResponses}
            recommendedIngredients={props.recommendedIngredients}
            handleSaveUserResponsesMulti={props.handleSaveUserResponsesMulti}
            getUserName={props.getUserName}
            lastStep={props.lastStep}
          />
        </div>
      )}
    </div>
  );
}
