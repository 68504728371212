import { useEffect, useState, useRef } from 'react';
import ButtonsBlock from "../ButtonsBlock/ButtonsBlock";
import { options, questionsParams } from '../../data/options';

export default function MultiChoiceQuestion({ props }) {
  const [answers, setAnswers] = useState([]);
  const buttonTextRef = useRef(null);

  //function to calculate how many questions should be added after acne question
  function checkQuestionsAmountUpdate(response, action) {
      let addQuestions = 4;

      if (action === 'remove') {
        if (response.responseText === 'Acne breakouts') {
          if (answers.length === 1) {
            addQuestions += 2;
          } else {
            addQuestions = 2;
          }
          return addQuestions;
        }
  
        if (response.responseText === 'Pimples') {
          if (answers.length === 1) {
            addQuestions += 1;
          } else {
            addQuestions = 1;
          }
          return addQuestions;
        }
  
        if (response.responseText === 'Black-heads' || response.responseText === 'White-heads') {
          if (answers.length > 1) {
            addQuestions = 0;
          }
          return addQuestions;
        }
      } else {
        if (response.responseText === 'Acne breakouts') {
          if (answers.length === 0) {
            addQuestions += 2;
          } else {
            addQuestions = 2;
          }
          return addQuestions;
        }
  
        if (response.responseText === 'Pimples') {
          if (answers.length === 0) {
            addQuestions++;
          } else {
            addQuestions = 1;
          }
          return addQuestions;
        }
  
        if (response.responseText === 'Black-heads' || response.responseText === 'White-heads') {
          if (answers.length > 0) {
            addQuestions = 0;
          }
          return addQuestions;
        }
      }
  }

  function handleCheckboxChange(e) {
    let newScores = [];
    const response = options[props.questionNumber].find(option => option.responseText === e.target.value);

    //option already was chosen: delete the chosen option from the array of answers
    if (answers.includes(e.target.value)) {
      //delete from local array
      const newAnswers = answers.filter(item => item !== e.target.value);
      setAnswers(newAnswers);
      //update user score
      for (let key in response.score) {
        newScores[key] = props.userScores[key] - response.score[key];
        props.updateUserScores(newScores);
      }
      //save updated responses globally
      props.handleMultiResponses(props.questionNumber, e.target.value, 'remove');

      //update block length if needed
      if (props.updateBlockLength && response.additionalQuestionsAmount) {
        if (props.questionNumber === 4) {
          props.updateBlockLength(0 - checkQuestionsAmountUpdate(response, 'remove'))
        } else {
          props.updateBlockLength(0 - response.additionalQuestionsAmount);
        }
      }
    } else {
      //option wasn't chosen: add the chosen option to the array of answers
      //add the option to a local array
      setAnswers((prev) => [...prev, e.target.value]);
      //update user score
      for (let key in response.score) {
        newScores[key] = props.userScores[key] + response.score[key];
        props.updateUserScores(newScores);
      }
      //save updated responses globally
      props.handleMultiResponses(props.questionNumber, e.target.value, 'push');
      //update block length if needed
      if (props.updateBlockLength && response.additionalQuestionsAmount) {
        if (props.questionNumber === 4) {
          props.updateBlockLength(checkQuestionsAmountUpdate(response, 'push'))
        } else {
          props.updateBlockLength(response.additionalQuestionsAmount);
        }
      }
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    props.nextInnerStep(props.nextQuestion);
  }

  useEffect(() => {
    if (props.updateCategory) props.updateCategory(answers);
    if (answers.length === 0) {
      buttonTextRef.current.innerText = props.buttonInitialText || "none";
    } else {
      buttonTextRef.current.innerText = "next";
    }
  }, [answers]);

  return (
    <form
      className='multi-choice-question-form question-inner'
      onSubmit={handleSubmit}
    >
      <div className='multi-choice-question-question-content'>
        {options[props.questionNumber].map((option, i) => (
          <label key={i} className='multi-choice-question-label' >
            <div className='multi-choice-question-response-container'>
              <input
                className='multi-choice-question-input'
                type='checkbox'
                name={option.responseText}
                value={option.responseText}
                onChange={handleCheckboxChange}
              />
              <span className='multi-choice-question-checkbox' />
              <p className='multi-choice-question-response'>{option.responseText}</p>
            </div>
          </label>
        ))}
      </div>

      <ButtonsBlock
        previousInnerStep={props.previousInnerStep}
        previousQuestion={props.previousQuestion}
        nextType='submit'
        buttonTextRef={buttonTextRef}
      />
    </form>
  )
}