const Discount_20 = "MYFRE20";
const Discount_40 = "MYFRE40";

//Cart links
export function buildCartLinkSingleItem(itemID,url){
    if(url.slice(-1)==='1'){ // if the cart is not empty
       return url.concat(','+itemID+":1");
    }
     return url.concat(itemID+":1"); 
}

export function buildCartLinkSet(setID,url){
  if(url.slice(-1)==='1'){ // if the cart is not empty
    const result = url+","+setID.join(":1,");
    return(result+":1");
  }
  const result = url+setID.join(":1,");
  return(result+":1");
}

// Discount 
export function calculateDiscount(discount,price){
  return ((price*discount)/100);
}


export function appendDiscount(discount,url){
    const discountString = "?discount="

    if(discount === 20){
      return url+discountString.concat(Discount_20);
    }
    else if(discount === 40){
      return url+discountString.concat(Discount_40);
    }
}        
    

