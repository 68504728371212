import logoKamedis from '../../icons/logo-kamedis.svg';
import logoMeNow from '../../icons/menow_logo.svg';
import ContainerProgressBar from "../ContainerProgressBar/ContainerProgressBar";
import { useState, useEffect } from 'react';

export default function Header(props) {
  const [headerTitle, setHeaderTitle] = useState(props.responses[props.questionsCounter].headerTitle);

  useEffect(() => {
    if (props.responses[props.questionsCounter].headerTitle.includes('[Name]')) {
      setHeaderTitle(props.responses[props.questionsCounter].headerTitle.replace('[Name]', props.name));
    } else {
      setHeaderTitle(props.responses[props.questionsCounter].headerTitle)
    }
  }, [props.questionsCounter])

  return (
    <div className="header">
      <img className="header__logo" src={logoKamedis} />

      <div className="header__container">
        <div className="header__title-container">
          <h1 className="header__title">{headerTitle}</h1>
          {props.questionsCounter === 0
            && <p className="header__subtitle">Start the quiz and discover your ideal match.</p>
          }
        </div>

        <ContainerProgressBar
          blocksLength={props.blocksLength}
          moveStep={props.moveStep}
          isOnStep={props.isOnStep}
          questionsCounter={props.questionsCounter}
        />
      </div>

      <div className="header__menow">
        <p className="header__powered-by">Powered by</p>
        <a href="http://menow.ai" target="_blank">
          <img className="header__logo-menow" src={logoMeNow} />
        </a>
      </div>
    </div>
  )
}
