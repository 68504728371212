import Button from "../Button/Button";
import BackButton from "../BackButton/BackButton";

export default function ButtonsBlock(props) {
  return (
    <div className="question-button-container">
      <BackButton
        previousInnerStep={props.previousInnerStep}
        previousQuestion={props.previousQuestion} />
      {props.isNextOptional
        ? props.isChoice
        && <Button
          type={props.nextType}
          buttonText={props.buttonText}
          onClick={props.onNextClick} />
        : <Button
          type={props.type}
          textRef={props.buttonTextRef}
          buttonText={props.buttonText}
          onClick={props.onNextClick} />}
    </div>
  )
}