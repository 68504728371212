import { motion } from "framer-motion";
import IngredientImage from "../IngredientImage/IngredientImage";
import { COMPANY } from "../../utils/constants";

const ImgUrl = "https://aiodefilesbucket.s3.amazonaws.com/kamedising/"; //{id}.jpg`;

export default function TopIngredients(props) {
  return (
    <div className="top-ingredients">
      <div
        transition={{ ease: "linear", duration: 0.5 }}
        className="top-ingredients-content"
      >
        {props.questionsCounter >= 0 && props.questionsCounter <= 2 && (
          <p className="top-ingredients-text">
            {COMPANY} uses unique
            <span className="top-ingredients-text_highlighted">
              {" "}
              active complexes{" "}
            </span>
            from medicinal plants with
            <span className="top-ingredients-text_highlighted">
              {" "}
              therapeutic benefits{" "}
            </span>
            on the skin.
          </p>
        )}

        <div className="top-ingredients-images-container">
          {props.recommendedIngredients.concat(props.recommendedIngredients).map((ingredient) => (
            <IngredientImage
              height="100%"
              src={`${ImgUrl + ingredient.ID}.jpg`}
              key={ingredient.ID}
              name={ingredient.name}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
