import { useEffect } from 'react';
import ColorCircle from '../ColorCircle/ColorCircle';
import { useState } from "react";
import lightOneimg from '../../images/skin-color-picker/lightOne.svg';
import lightTwoimg from '../../images/skin-color-picker/lightTwo.svg';
import tannedImg from '../../images/skin-color-picker/tanned.svg';
import darkerOneImage from '../../images/skin-color-picker/darkerOne.svg';
import darkerTwoImage from '../../images/skin-color-picker/darkerTwo.svg';

export default function ColorPicker({props}) {
  const [isChoice, setIsChoice] = useState(false);
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedScore, setSelectedScore] = useState(0);

  const light1 = lightOneimg ? `url(${lightOneimg})` : '#FFF3EC'
  const light2 = lightTwoimg ? `url(${lightTwoimg})` : '#EEDDC9'
  const light3 = tannedImg ? `url(${tannedImg})` : '#DFC9AB'
  const dark1 = darkerOneImage ? `url(${darkerOneImage})` : '#C3A988'
  const dark2 = '#967759'
  const dark3 = darkerTwoImage ? `url(${darkerTwoImage})` : '#684328'

  /* For age we will save the answer and update it in userScores */
  function handleUntannedSkin(color, score) {
    setIsChoice(true);
    props.updateUserScores({ Photoaging: props.userScores.Photoaging - selectedScore + score });
    setSelectedScore(score);
    props.updateUserResponses(props.step, props.question, color);

    if (selectedColor !== color) {
      setSelectedColor(color);
      // props.getRecommendedIngredients(props.topIngredientsNumber);
    }
    props.nextInnerStep(props.nextQuestion);
  }

  useEffect(() => {
    console.warn('selected score: ', selectedScore);
  }, [selectedScore])

  function handleBackClick() {
    props.previousInnerStep(props.previousQuestion);
  }

  function handleNextClick() {
    props.nextInnerStep(props.nextQuestion);
  }

  return (
    <>
      <div className="color-picker">
        <ColorCircle
          color={light1}
          handleSelectResponse={handleUntannedSkin}
          score={2}
          selectedColor={selectedColor}
          text='Pale'
        />
        <ColorCircle
          color={light2}
          handleSelectResponse={handleUntannedSkin}
          score={1}
          selectedColor={selectedColor}
        />
        <ColorCircle
          color={light3}
          handleSelectResponse={handleUntannedSkin}
          score={0}
          selectedColor={selectedColor}
          text='Tanned'
        />
        <ColorCircle
          color={dark1}
          handleSelectResponse={handleUntannedSkin}
          score={0}
          selectedColor={selectedColor}
        />
        <ColorCircle
          color={dark3}
          handleSelectResponse={handleUntannedSkin}
          score={0}
          selectedColor={selectedColor}
          text='Dark'
        />
      </div>

      <div className="question-button-container">
        <button
          className="question-back-button"
          type='button'
          onClick={handleBackClick} />
        {isChoice
          && <button
            type='button'
            className='button'
            onClick={handleNextClick}>next</button>}
      </div>
    </>
  );
}
