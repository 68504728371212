import { useState } from 'react';

function WordBreaker({ word, className }) {
  const breakWord = (word) => {
    if (!word.includes(' ') && word.length > 12) {
      let prefix;
      let suffix;
      if (word.length < 17) {
        prefix = word.substr(0, word.length - 4);
        suffix = word.substr(-4);
      } else {
        prefix = word.substr(0, 12);
        suffix = word.slice(11);
      }

      return (
        <>
          {prefix}<br />
          {suffix}
        </>
      )
    }
    return word;
  }

  if (word) {
    return <p className={className}>{breakWord(word)}</p>
  } 
}

export default function Ingredient(props) {
  const [isImageLoaded, setisImageLoaded] = useState(false);

  const loadController = () => {
    if (!isImageLoaded) {
      setisImageLoaded(true);
    } else {
      setisImageLoaded(false);
    }
  };

  return (
    <div
    className={props.isCalculated 
      ? 'top-ingredients-ingredient top-ingredients-ingredient_calculated' 
      : 'top-ingredients-ingredient'}
      id={props.id}
      key={props.id}
    >
      <img
        className="top-ingredients-image"
        src={props.src}
        alt={props.ingredientName}
        onLoad={loadController}
      />
      <p className="top-ingredients-name">{props.ingredientName}</p>
    </div>
  );
}
