import "./CounterBlock.css";
import block2 from "../../data/block2.json";

import {
  NUMBER_OF_QUESTION_BLOCK_ONE, //TODO create a dynamic length with first last index
  NUMBER_OF_QUESTION_BLOCK_TWO, //TODO create a dynamic length with first last index
  NUMBER_OF_QUESTION_BLOCK_THREE, //TODO create a dynamic length with first last index
} from "../../utils/constants";

function CurrentQuestionsBlock(props) {
  const blockLengths = [
    NUMBER_OF_QUESTION_BLOCK_ONE,
    NUMBER_OF_QUESTION_BLOCK_TWO,
    NUMBER_OF_QUESTION_BLOCK_THREE,
  ];

  const commonClass = (hover = false) =>
    `questions-block-color-fill${hover ? " hover" : ""}`;

  const renderPogressBarColor = () => {
    const blockLength = blockLengths[props.blockId - 1];
    // const pointer =

    let widthPercentage = (props.questionsCounter / blockLength) * 100 + "%"; //(props.questionsCounter / NUMBER_OF_QUESTION_BLOCK_ONE) * 100 + "%";
    if (
      props.questionsCounter >= 1 &&
      props.questionsCounter <= 14 &&
      props.blockId === 1
    ) {
      //if (props.blockId === 1) {
      return (
        <p className={commonClass()} style={{ width: widthPercentage }}></p>
      );
      //}
    }

    if (props.questionsCounter >= 15 && props.questionsCounter <= 18) {
      if (props.blockId === 1) {
        return <p className={commonClass(true)} style={{ width: "100%" }}></p>;
      } else if (props.blockId === 2) {
        widthPercentage =
          (block2[props.questionsCounter] / blockLength) * 100 + "%";

        return (
          <p className={commonClass()} style={{ width: widthPercentage }}></p>
        );
      }
    }

    if (props.questionsCounter >= 19) {
      if (props.blockId === 1 || props.blockId === 2) {
        return <p className={commonClass(true)} style={{ width: "100%" }}></p>;
      } else {
        widthPercentage =
          (block2[props.questionsCounter] / blockLength) * 100 + "%";
        return (
          <p className={commonClass()} style={{ width: widthPercentage }}></p>
        );
      }
    }
  };

  return (
    <div className={`current-questions-block `}>{renderPogressBarColor()}</div>
  );
}

export default function CounterBlock(props) {
  const handleClick = () => {
    props.moveStep(props.stepCount, props.innerBlockOrder);
  };

  return (
    <div onClick={handleClick} className={`counter-block`}>
      <CurrentQuestionsBlock
        blockId={props.blockId}
        numberOfQuestionsInBlock={props.numberOfQuestionsInBlock}
        questionsCounter={props.questionsCounter}
        groupQuestionsByCategory={props.groupQuestionsByCategory}
        blocksLength={props.blocksLength}
      />
    </div>
  );
}
