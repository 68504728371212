import Ingredient from '../Ingredient/Ingredient';

const ImgUrl = 'https://aiodefilesbucket.s3.amazonaws.com/kamedising/'; //{id}.jpg`;

export default function ScoreCalculatedTopIngredients(props) {
  return (
    <div
      className='top-ingredients top-ingredients_calculated'
    >
      {/* <div className="top-ingredients-logo" /> */}
      <div className="top-ingredients-content top-ingredients-content_calculated">
        <div className="top-ingredients-text score-calculated-top-ingredients-text">
          Your Top Ingredients:
        </div>
        <div className={props.recommendedIngredients.length === 2
          ? "top-ingredients-images-container_calculated top-ingredients-images-container_two-ingredients"
          : "top-ingredients-images-container_calculated"}>
          {
            /* TODO if i get Empty array to have offline image  */
            props.recommendedIngredients.map((ingredient) => (
              <Ingredient
                ingredient={ingredient}
                ImgUrl={ImgUrl}
                key={ingredient.id}
                id={ingredient.id}
                ingredientName={ingredient.name}
                src={`${ImgUrl + ingredient.id}.jpg`}
                isCalculated={true}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}
