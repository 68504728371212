import styled, { keyframes } from "styled-components";

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

const StyledPointerProgressBar = styled.p`
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 130%;
  width: 228px;
  padding-top: 10px;
  font-family: "NarkissBlockExtended", sans-serif;
  font-weight: 300;
  margin-left: ${(props) => props.marginValue || "10px"};
  // animation: ${bounceAnimation} 1s ease;
  transition: margin-left 0.5s ease;
`;
export default StyledPointerProgressBar;
