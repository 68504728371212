import IngredientImage from "../IngredientImage/IngredientImage";

export default function IngredientInfo(props) {
  const { ingredientTitle, ingredientDescription, ingredientBenefits, ingredientImage, imageDimentions } = props;
  
  return (
    <div className="ingredient-info">
      <IngredientImage 
        width={imageDimentions}
        height={imageDimentions}
        src={ingredientImage}
        name={ingredientTitle}
        isOverlay={true}
        border={true}
      />        
      <p className="ingredient-info-description">
        <span className="ingredient-info-title">{ingredientTitle} </span>
        {ingredientDescription}
      </p>
      <p className="ingredient-benefits">{ingredientBenefits}</p>
    </div>
  )
}
