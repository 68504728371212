import SliderContainer from "../SliderContainer/SliderContainer";
import Slider from "@mui/material/Slider";
import ButtonsBlock from "../ButtonsBlock/ButtonsBlock";
import { useEffect } from "react";
import { useState } from 'react';

export default function SliderQuestion({ props }) {
  const [currentScore, setCurrentScore] = useState(props.score[props.defaultSliderValue]);
  const [isSliderChanged, setIsSliderChanged] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);

  function handleChange(e, newValue) {
    let newScores = {};
    if (!isSliderChanged) {
      if (isNextClicked) {
        for (let key in props.score[props.defaultSliderValue]) {
          newScores[key] = props.userScores[key] - props.score[props.defaultSliderValue][key];
        }
      }
    } else {
      for (let key in currentScore) {
        newScores[key] = props.userScores[key] - currentScore[key];
      }
    }

    for (let key in props.score[newValue]) {
      const temp = newScores[key] !== undefined ? newScores[key] : props.userScores[key];
      newScores[key] = temp + props.score[newValue][key];
    }
    setIsSliderChanged(true);
    props.updateUserScores(newScores);
    setCurrentScore(props.score[newValue]);
    props.updateUserResponses(props.questionNumber, newValue);
  }

  function handleNextClick() {
    if (!isSliderChanged && !isNextClicked) {
      for (let key in props.score[props.defaultSliderValue]) {
        const newScores = [];
        newScores[key] = props.userScores[key] + props.score[props.defaultSliderValue][key];
        props.updateUserScores(newScores);
      }
    }

    setIsNextClicked(true);
    props.nextInnerStep(props.nextQuestion);
  }

  useEffect(() => {
    //console.log(currentScore);
  }, [currentScore]);

  return (
    <div className="question-inner">
      <SliderContainer
        texts={props.texts}
        sliderTextLeft={props.sliderTextLeft}
        sliderTextRight={props.sliderTextRight}
        sliderTextCenter={props.sliderTextCenter}
      >
        <Slider
          defaultValue={props.defaultSliderValue}
          marks={props.marks}
          step={null}
          sx={props.sliderStyle}
          onChange={handleChange}
        />
      </SliderContainer>

      <ButtonsBlock
        previousInnerStep={props.previousInnerStep}
        previousQuestion={props.previousQuestion}
        nextType='button'
        buttonText='next'
        onNextClick={handleNextClick}
      />
    </div>
  )
}