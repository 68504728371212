import Button from '../Button/Button'; 
import { useState, useEffect } from 'react';

import './Carts.css';

export default function Cart(props) {

    const [scrollingClass, setScrollingClass] = useState('');

    const handleScroll = () => {
      if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        setScrollingClass('cart-container_scrolling');
      } else {
        setScrollingClass('');
      }
    };

    useEffect(() => {
      window.addEventListener("scroll", handleScroll, true);
      return () => {
        window.removeEventListener("scroll", handleScroll, true);
      };
    }, []);

    return(
        <div className={`cart-container ${scrollingClass}`}  onClick={(e) => {
            props.goToCheckout(e, props.cartUrl);
         }}> 
            <div className='cart-text'>Cart {Number(props.price).toFixed(2)} ₪</div>
            <div className='cart-logo-container'>
              <div className='cart-logo'></div>
              <div className='amount-text'>{props.itemCount}</div>
            </div>
        </div>
    );
}