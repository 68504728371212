import IngredientInfo from "../IngredientInfo/IngredientInfo";
import { IngredientCarousel } from "../IngredientCarousel/IngredientCarousel";
import { COMPANY } from "../../utils/constants";
import useScreenSize from "../../utils/useScreenSize";

const ImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/kamedising/{id}.jpg`;

export default function BestIngredients(props) {
  console.log(props);
  const [screenWidth] = useScreenSize();
  const imageDimentions = screenWidth > 1240 ? "28rem" : "15.4rem";

  const ingredientComponents = props.recommendedIngredients.map(
    (ingredient) => {
      const {
        id,
        name,
        "Short definition": short_definition,
        "Kamedis Description": kamedis_description,
      } = ingredient;
      console.log("ingrdient --> ", ingredient, id, name);
      return (
        <IngredientInfo
          key={id}
          ingredientImage={ImgUrl.replace("{id}", id)}
          imageDimentions={imageDimentions}
          ingredientTitle={name}
          ingredientDescription={ingredient.short_definition}
          ingredientBenefits={ingredient.kamedis_description}
        />
      );
    }
  );

  return (
    <div className="best-ingredients">
      <div className="best-ingredients-title">
        Your best {COMPANY} ingredients:
      </div>
      {screenWidth > 748 ? (
        <div className="best-ingredients-info-container">
          {ingredientComponents}
        </div>
      ) : (
        <IngredientCarousel
          recommendedIngredients={props.recommendedIngredients}
        />
      )}
    </div>
  );
}
