import sun01 from "../icons/sun_0-1.svg";
import sun24 from "../icons/sun_2-4.svg";
import sun05 from "../icons/sun_5.svg";
import cup13 from "../icons/cup_1-3.svg";
import cup46 from "../icons/cup_4-6.svg";
import cup7 from "../icons/cup_7.svg";

const COMPANY = "Kamedis";

////////////////////////Used for slider////////////////////////

const marks = [
  {
    value: 0,
    label: "1",
  },
  {
    value: 25,
    label: "2",
  },
  {
    value: 50,
    label: "3",
  },
  {
    value: 75,
    label: "4",
  },
  {
    value: 100,
    label: "5",
  },
];

const GreenRedSliderStyles = {
  '& .MuiSlider-thumb': {
    color: "#191919",
    boxShadow: 'none',
  },
  "& .MuiSlider-active": {
    boxShadow: "none",
  },
  "& .MuiSlider-track": {
    opacity: "0",
  },
  '& .MuiSlider-rail': {
    color: 'white',
    background: 'linear-gradient(90deg, #AAD0B1, #CFE186, #EFBFA4)',
    opacity: '1',
    height: "8px",
    width: "calc(100% + 4px)",
  },
  '& .MuiSlider-markLabel': {
    color: "#A0D1AF",
    fontSize: "18px",
  },
  '& .MuiSlider-mark': {
    width: "4px",
    height: "4px",
    color: "#000",
    borderRadius: "50%"
  },
};

const GreenBlueSliderStyles = {
  '& .MuiSlider-thumb': {
    color: "#191919",
    boxShadow: 'none',
  },
  "& .MuiSlider-active": {
    boxShadow: "none",
  },
  "& .MuiSlider-track": {
    opacity: "0",
  },
  '& .MuiSlider-rail': {
    color: 'white',
    background: 'linear-gradient(90deg, #AAD0B1, #ADC1E3)',
    opacity: '1',
    height: "8px",
    width: "calc(100% + 4px)",
  },
  '& .MuiSlider-markLabel': {
    color: "#A0D1AF",
    fontSize: "18px",
  },
  '& .MuiSlider-mark': {
    width: "4px",
    height: "4px",
    color: "#000",
    borderRadius: "50%"
  },
};

const NUMBER_OF_QUESTION_BLOCK_ONE = 14;
const NUMBER_OF_QUESTION_BLOCK_TWO = 4;
const NUMBER_OF_QUESTION_BLOCK_THREE = 6;

export {
  COMPANY,
  marks,
  GreenRedSliderStyles,
  GreenBlueSliderStyles,
  sun01,
  sun24,
  sun05,
  cup13,
  cup46,
  cup7,
  NUMBER_OF_QUESTION_BLOCK_ONE,
  NUMBER_OF_QUESTION_BLOCK_TWO,
  NUMBER_OF_QUESTION_BLOCK_THREE,
};
