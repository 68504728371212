import { useState } from "react";
import ButtonsBlock from "../ButtonsBlock/ButtonsBlock";
import { options, questionsParams } from '../../data/options';

export default function SingleChoiceQuestion({ props }) {
  const [selectedScore, setSelectedScore] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isChoice, setIsChoice] = useState(false);

  function handleClick(e) {
    setIsChoice(true);
    if (e.target.value === selectedOption) {
      props.nextInnerStep(props.nextQuestion);
    }
  }

  function handleNextClick() {
    props.nextInnerStep(props.nextQuestion);
  }

  function handleChange(e) {
    let newScores = [];
    const response = options[props.questionNumber].find(option => option.responseText === e.target.value);

    if (selectedScore) {
      for (let key in selectedScore) {
        const newScore = response.score ? response.score[key] : 0;
        newScores[key] = props.userScores[key] - selectedScore[key] + newScore;
        props.updateUserScores(newScores);
      }
    } else {
      if (response.score) {
        for (let key in response.score) {
          newScores[key] = props.userScores[key] + response.score[key];
          props.updateUserScores(newScores);
        }
      }
    }
    props.updateUserResponses(props.questionNumber, e.target.value)

    setSelectedScore(response.score);
    setSelectedOption(e.target.value);
    if (response.updateCategory) response.updateCategory();

    props.nextInnerStep(props.nextQuestion);
  }

  return (
    <form className='single-choice-question-form question-inner' onSubmit={props.nextInnerStep}>
      <div className="single-choice-question-content">
        {options[props.questionNumber].map((option, i) =>
        (
          <label key={i} className='single-choice-question-label' >
            <input
              className="single-choice-question-input"
              type='radio'
              value={option.responseText}
              name={props.questionTitle}
              key=''
              onChange={handleChange}
              onClick={handleClick}
            />
            <div className='single-choice-question-option'>
              {option.icon && (
                <img src={option.icon} className='single-choice-question-icon' />
              )}
              <p className='single-choice-question-response'>{option.responseText}</p>
            </div>
          </label>
        )
        )}
      </div>

      <ButtonsBlock
        previousInnerStep={props.previousInnerStep}
        previousQuestion={props.previousQuestion}
        nextType='button'
        buttonText={'next'}
        onNextClick={handleNextClick}
        isNextOptional={true}
        isChoice={isChoice}
      />
    </form>

  )
}