import { useEffect } from "react"

const useExitPageWarning = () => {
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
}

export default useExitPageWarning;