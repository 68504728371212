import { useEffect } from 'react';
import Button from '../Button/Button';
import SmoothieIngrediant from '../SmoothieIngrediant/SmoothieIngrediant';

import kamedisLogo from '../../icons/logo-kamedis.svg';
import logoMeNow from '../../icons/menow_logo.svg';
import Loading from '../../images/loading.gif';

export default function SmoothieStep(props) {
  const smoothieIngredients = props.smoothieIngredients; // 3 ingredients of recommended smoothie
  const smoothie = props.smoothie;

  // Makes sure top of page is in view when component first mounts
  useEffect(() => {
    const topOfPage = document.getElementById("root");
    topOfPage.scrollIntoView();
  }, []);

  useEffect(() => {
    if (smoothieIngredients !== undefined && smoothie !== undefined) {
      console.log('smoothieIngredients----', smoothieIngredients);
      console.log('smoothie----', smoothie);
      console.log('name:', smoothie);
    }
  }, [smoothieIngredients, smoothie]);

  const SmoothieIngrediantComponents = smoothieIngredients.map((ingredient) => {
    return (
      <SmoothieIngrediant
        key={ingredient.ID}
        ingredientImageID={ingredient.ID}
        alt={'smoothieIngrediant'}
        ingredientTitle={ingredient.Ingredient}
        ingredientSubtitle={ingredient.Benefits}
      />
    );
  });

  if (props.isOnStep === 2) {
    return (
      <main className="smoothie-step">
        <section className='smoothie-step-page-top'>
          <div className='smoothie-step-header'>
            <img className="kamedis-logo" src={kamedisLogo} alt={'Kamedis Logo'} />
            <button className="main-close-btn main-close-btn-smoothies" onClick={props.closeTheQuiz} />
            <div className="header__menow header__menow-smoothies">
              <p className="header__powered-by">Powered by</p>
              <a href="http://menow.ai" target="_blank">
                <img className="header__logo-menow" src={logoMeNow} alt='logo menow'/>
              </a>
            </div>
          </div>
          <div className='smoothie-step-banner'>
            <h1 className="smoothie-step-title">
              {
                props.isResultReady
                ? `Results are ready!`
                : `We’re preparing your results`
              }
            </h1>
            <p className="smoothie-step-subtitle">
            While you wait, mix up your exclusive smoothie — a perfect match for your lifestyle!
            This recipe consists of 3 ingredients selected especially for you based on your answers to the survey questions.
            </p>
          </div>
        </section>
        <section className="smoothie-step-ingredient-container">
          <div className='measurements-container'>
            <span className='measurement-amount'>1/3</span>
            <span className='measurement-amount'>1/3</span>
            <span className='measurement-amount'>1/3</span>
          </div>
          <div className='ingredients-container'>
              {SmoothieIngrediantComponents}
          </div>
        </section>
        <div className='blend-your-ingredients'>Blend your ingredients</div>
        { props.isResultReady 
          ? <Button
            className='button_smoothie-step'
            buttonText={'get my results'}
            onClick={props.onClick}
            />
          : <div className='loading-container'>
              <img 
                src={Loading}
                alt='loading'
              />
          </div>
        }
        
      </main>
    );
  }
}
