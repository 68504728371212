import axios from "axios";

axios.defaults.baseURL = "https://api.kamedis.menow.ai/";

const fetchProductScoresData = async () => {
  try {
    const response = await axios.get("product-scores");
    console.log("product-scores", response);
    return response.data;
  } catch (err) {
    console.log("error product-scores", err);
  }
};

export const productScoresService = {
  fetchProductScoresData,
};
