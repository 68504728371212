import { 
  sun01, 
  sun24, 
  sun05,
  cup13,
  cup46,
  cup7, 
} from "../utils/constants";

const options = [
  [],
  [
    {
      responseText: 'Sensitive/irritated skin',
      score: {
        "Sensitive Skin": 1,
        "Pigmentation": 0,
        "Acne": 0,
        "Aging": 0
      },
      additionalQuestionsAmount: 2
    },
    {
      responseText: 'Pigmentation changes',
      score: {
        "Sensitive Skin": 0,
        "Pigmentation": 1,
        "Acne": 0,
        "Aging": 0
      },
      additionalQuestionsAmount: 2
    },
    {
      responseText: 'Acneic skin issues',
      score: {
        "Sensitive Skin": 0,
        "Pigmentation": 0,
        "Acne": 1,
        "Aging": 0
      },
      additionalQuestionsAmount: 1
    },
    {
      responseText: 'Signs of aging',
      score: {
        "Sensitive Skin": 0,
        "Pigmentation": 0,
        "Acne": 0,
        "Aging": 1
      },
    },
    {
      responseText: 'Scalp issues',
      additionalQuestionsAmount: 1
    },
  ],
  [
    {
      responseText: 'Rosacea',
      score: {
        Rosacea: 10,
        Psoriasis: 0,
        Seborrhea: 0,
        "Atopic Dermatitis/ Eczema": 0,
        "Dry Skin": 0,
        "Sensitive Skin": 0
      },
    },
    {
      responseText: 'Psoriasis',
      score: {
        Rosacea: 0,
        Psoriasis: 10,
        Seborrhea: 0,
        "Atopic Dermatitis/ Eczema": 0,
        "Dry Skin": 0,
        "Sensitive Skin": 0
      },
    },
    {
      responseText: 'Eczema/Atopic dermatitis',
      score: {
        Rosacea: 0,
        Psoriasis: 0,
        Seborrhea: 0,
        "Atopic Dermatitis/ Eczema": 10,
        "Dry Skin": 1,
        "Sensitive Skin": 0
      },
    },
    {
      responseText: 'Seborrhea',
      score: {
        Rosacea: 0,
        Psoriasis: 0,
        Seborrhea: 10,
        "Atopic Dermatitis/ Eczema": 0,
        "Dry Skin": 0,
        "Sensitive Skin": 0
      },
    },
    {
      responseText: 'Reactive and intolerant skin',
      score: {
        Rosacea: 0,
        Psoriasis: 0,
        Seborrhea: 0,
        "Atopic Dermatitis/ Eczema": 0,
        "Dry Skin": 0,
        "Sensitive Skin": 10
      },
    },
    {
      responseText: 'Skincare product allergy tendency',
      score: {
        Rosacea: 0,
        Psoriasis: 0,
        Seborrhea: 0,
        "Atopic Dermatitis/ Eczema": 2,
        "Dry Skin": 0,
        "Sensitive Skin": 5
      },
    },
  ],
  [
    {
      responseText: 'Itchy skin/Rash',
      score: {
        "Sensitive Skin": 3,
        "Atopic Dermatitis/ Eczema": 3,
        Rosacea: 0,
        Seborrhea: 0,
        "Dry Skin": 0,
      },
    },
    {
      responseText: 'Blood vessels',
      score: {
        "Sensitive Skin": 0,
        "Atopic Dermatitis/ Eczema": 0,
        Rosacea: 5,
        Seborrhea: 0,
        "Dry Skin": 0,
      },
    },
    {
      responseText: 'Flakes',
      score: {
        "Sensitive Skin": 0,
        "Atopic Dermatitis/ Eczema": 1,
        Rosacea: 0,
        Seborrhea: 5,
        "Dry Skin": 0,
      },
    },
    {
      responseText: 'Dry and fragile',
      score: {
        "Sensitive Skin": 0,
        "Atopic Dermatitis/ Eczema": 0,
        Rosacea: 0,
        Seborrhea: 0,
        "Dry Skin": 5,
      },
    },
    {
      responseText: 'Reactive and intolerant skin',
      score: {
        "Sensitive Skin": 5,
        "Atopic Dermatitis/ Eczema": 2,
        Rosacea: 0,
        Seborrhea: 0,
        "Dry Skin": 0,
      },
    },
  ],
  [
    {
      responseText: 'Acne breakouts',
      score: { Acne: 2, Pigmentation: 0 },
      additionalQuestionsAmount: 4
    },
    {
      responseText: 'Pimples',
      score: { Acne: 2, Pigmentation: 0 },
      additionalQuestionsAmount: 4
    },
    {
      responseText: 'Black-heads',
      score: { Acne: 1, Pigmentation: 0 },
      additionalQuestionsAmount: 4
    },
    {
      responseText: 'White-heads',
      score: { Acne: 2, Pigmentation: 0 },
      additionalQuestionsAmount: 4
    },
    {
      responseText: 'Post-acne scars',
      score: { Acne: 0, Pigmentation: 3 },
    },
  ],
  [],
  [],
  [],
  [
    {
      responseText: 'Hormonal changes',
    },
    {
      responseText: 'Lack of sleep',
    },
    {
      responseText: 'Weather',
    },
    {
      responseText: 'Applying cosmetic products',
    },
    {
      responseText: 'Wearing a mask',
    },
    {
      responseText: 'Nutrition',
    },
  ],
  [
    {
      responseText: 'It helps, and my skin feels comfortable',
      score: {
        'Oily Skin': 1,
        'Dry Skin': 0,
        Acne: 0
      },
    },
    {
      responseText: 'Usually useless, but my skin feels fine',
      score: {
        'Oily Skin': 0,
        'Dry Skin': 0,
        Acne: 1
      },
    },
    {
      responseText: 'It works, but leaves my skin feeling dry and itchy',
      score: {
        'Oily Skin': 0,
        'Dry Skin': 1,
        Acne: 0
      },
    },
    {
      responseText: 'Never tried',
    }
  ],
  [
    {
      responseText: 'Yes',
      score: {
        'Dry Skin': 1,
      },
    },
    {
      responseText: 'No',
    },
    {
      responseText: 'Prefer not to say',
    },
  ],
  [
    {
      responseText: 'Yes',
      score: {
        'Dry Skin': 1,
      },
    },
    {
      responseText: 'No',
    },
    {
      responseText: 'Prefer not to say',
    },
  ],
  [
    {
      responseText: 'Yes',
      score: {
        Photoaging: 2,
      },
    },
    {
      responseText: 'No',
    },
    {
      responseText: 'Not sure',
      score: {
        Photoaging: 1,
      },
    },
  ],
  [
    {
      responseText: 'Hormonal changes',
    },
    {
      responseText: 'Stress, fatigue',
    },
    {
      responseText: 'Acne scars',
    },
    {
      responseText: 'Medical issues',
    },
  ],
  [
    {
      responseText: 'Dundruff (flaking)',
      score: {
        Seborrhea: 1,
        "Sensitive Skin": 0
      },
    },
    {
      responseText: 'Sebborhea',
    },
    {
      responseText: 'Psoriasis',
    },
    {
      responseText: 'Itchy/Irritated/Redness',
      score: {
        Seborrhea: 0,
        "Sensitive Skin": 1
      },
    },
    {
      responseText: 'Sensitive',
    },
    {
      responseText: 'Hair loss',
    },
  ],
  [
    {
      responseText: 'Yes',
      score: { 'Dry Skin': 2 },
    },
    {
      responseText: 'No',
    },
    {
      responseText: 'Not sure',
      score: { 'Dry Skin': 1 },
    }
  ],
  [],
  [],
  [],
  [
    {
      responseText: '0 - 1',
      icon: sun01,
    },
    {
      responseText: '2 - 4',
      score: { Photoaging: 1 },
      icon: sun24,
    },
    {
      responseText: '5+',
      score: { Photoaging: 2 },
      icon: sun05,
    }
  ],
  [
    {
      responseText: '1 - 3',
      score: { 'Dry Skin': 2 },
      icon: cup13,
    },
    {
      responseText: '4 - 6',
      icon: cup46,
    },
    {
      responseText: '7+',
      icon: cup7,
    }
  ],
  [
    {
      responseText: 'Yes',
      score: { Photoaging: 2 },
    },
    {
      responseText: 'No',
    },
    {
      responseText: 'Sometimes',
      score: { Photoaging: 1 },
    }
  ],
  [],
  [
    {
      responseText: 'Female',
    },
    {
      responseText: 'Transwoman',
    },
    {
      responseText: 'Non-binary',
    },
    {
      responseText: 'Male',
    },
    {
      responseText: 'Transman',
    },            
    {
      responseText: 'Prefer not to say',
    },
  ],
  [
    {
      responseText: '20 or below',
    },
    {
      responseText: '21 - 30',
    },
    {
      responseText: '31 - 40',
      score: {
        Aging: 1
      },
    },
    {
      responseText: '41 - 50',
      score: {
        Aging: 2
      },
    },
    {
      responseText: '51 - 60',
      score: {
        Aging: 3
      },
    },
    {
      responseText: '61 and up',
      score: {
        Aging: 4
      },
    },
    {
      responseText: 'Prefer not to say',
    },
  ]
];

const questionsParams = [
  {
    title: 'Please tell us your name.',
    type: 'input'
  }
]

export {options, questionsParams};