import InputQuestion from "../InputQuestion/InputQuestion";
import SingleChoiceQuestion from "../SingleChoiceQuestion/SingleChoiceQuestion";
import MultiChoiceQuestion from "../MultiChoiceQuestion/MultiChoiceQuestion";
import SliderQuestion from "../SliderQuestion/SliderQuestion";
import ColorPicker from "../ColorPicker/ColorPicker";

export default function Question(props) {
  //variables to add unbreakable space for sentences ended with short words
  const subtitle = props.subtitle && props.subtitle.replace('&nbsp;', '\u00A0');
  const subtitleEnd = props.splitSubtitleEnding && props.splitSubtitleEnding.replace('&nbsp;', '\u00A0');

  return (
    <div
      className="question-container"
      style={{
        opacity: props.questionsCounter === props.questionNumber ? 1 : 0,
        display:
          props.questionsCounter === props.questionNumber
            ? "flex"
            : "none",
      }}
    >
      <div className="question">
        {props.subtitle && (
          <p className="question-subtitle">{subtitle}</p>
        )}
        {props.splitSubtitleCondition && (
          <p className="question-subtitle">
            {props.splitSubtitleBeginning}
            <span className={`question-subtitle-highlight_${props.highlightColor}`}>
              {props.splitSubtitleCondition}
            </span>
            {subtitleEnd}
          </p>
        )}
        <div className="question-title">
          {props.questionTitle}
        </div>
        {props.questionType === "single" && <SingleChoiceQuestion props={props} />}
        {props.questionType === "input" && <InputQuestion props={props} />}
        {props.questionType === "multi" && <MultiChoiceQuestion props={props} />}
        {props.questionType === "slider" && <SliderQuestion props={props} />}
        {props.questionType === "color" && <ColorPicker props={props} />}
      </div>
    </div>
  );
}
