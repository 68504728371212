import { useState } from 'react';

const ImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/smoothies_ing/{id}.png`;

export default function SmoothieIngrediant(props) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleIngredientLoad = () => {
    if (!isImageLoaded) {
      setIsImageLoaded(true);
    } else {
      setIsImageLoaded(false);
    }
  };

  return (
    <div className="smoothie-ingredient-container">
      <img
        src={ImgUrl.replace('{id}', props.ingredientImageID)}
        onLoad={handleIngredientLoad}
        alt={props.alt}
        className={
          isImageLoaded
            ? 'smoothie-ingredient-img'
            : 'smoothie-ingredient-img smoothie-ingredient-img_disabled'
        }
      />
      <div className='smoothie-ingredient-details'> 
        <h3 className="smoothie-ingredient-title">{props.ingredientTitle}</h3>
        <p className="smoothie-ingredient-subtitle">{props.ingredientSubtitle}</p>
      </div>
      <div className='mobile-measurement-amount'>1/3</div>
    </div>
  );
}
