import ProgressBarStep from "../ProgressBarStep/ProgressBarStep";

export default function ContainerProgressBar(props) {
  return (
    <div className="container-progress-bar">
      {props.questionsCounter > 0 ? (
        <ProgressBarStep
          blocksLength={props.blocksLength}
          steps={4}
          stepCount={1}
          moveStep={props.moveStep}
          isOnStep={props.isOnStep}
          innerStepCount={props.step1InnerCount}
          questionsCounter={props.questionsCounter}
        />
      ) : (
        <p className="container-progress-bar__text">
          Our AI-algorithm analyzes each ingredient in a product's formulation
          to provide highly accurate guidance based on your unique skin
          conditions and concerns.
        </p>
      )}
    </div>
  );
}
