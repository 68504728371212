export default function Button(props) {
  return (
    <button
      className={`button ${props.className && props.className}`}
      onClick={props.onClick}
      ref={props.textRef}
      type={props.type}
    >
      <span>{props.buttonText}</span>
    </button>
  )
}
